import React, {useEffect, useState} from 'react'
import {Button, ButtonGroup, CustomInput, FormGroup, Input, InputGroup, Label} from 'reactstrap';

const EditOrderForm = props => {
    const [currentFormState, setCurrentFormState] = useState(props.currentRow)

    useEffect(
        () => {
            setCurrentFormState(props.currentRow)
        },
        [props]
    )

    const handleInputChange = event => {
        const {name, value} = event.target

        setCurrentFormState({...currentFormState, [name]: value})
    }

    const handleCustomInputChange = order => {
        const {name, value} = order.target

        setCurrentFormState({...currentFormState, [name]: Number(value)})
    }

    const handleSwitchChange = (name, value) => () => {
        setCurrentFormState({...currentFormState, [name]: !value})
    }

    return (
        <form
            onSubmit={event => {
                event.preventDefault()

                props.updateRow(currentFormState.id, currentFormState)
            }}
        >
            <FormGroup>
                <label htmlFor="fkClient">Kunde</label>
                <CustomInput type="select"
                             name="fkClient"
                             id="fkClient"
                             onChange={handleCustomInputChange}
                             value={currentFormState.fkClient}
                             required>
                    {props.clients.map(option =>
                        <option key={option.id}
                                value={option.id}>{option.name}</option>
                    )}
                </CustomInput>
            </FormGroup>
            <FormGroup>
                <label htmlFor="fkItem">Bühne</label>
                <CustomInput type="select"
                             name="fkItem"
                             id="fkItem"
                             onChange={handleCustomInputChange}
                             value={currentFormState.fkItem}
                             required>
                    {props.items.map(option =>
                        <option key={option.id}
                                value={option.id}>{option.name}</option>
                    )}
                </CustomInput>
            </FormGroup>
            <FormGroup>
                <label htmlFor="fkFriend">Zuständig</label>
                <CustomInput type="select"
                             name="fkFriend"
                             id="fkFriend"
                             onChange={handleCustomInputChange}
                             value={currentFormState.fkFriend}
                             required>
                    {props.friends.filter(friend => friend.lead === 1).map(option =>
                        <option key={option.id}
                                value={option.id}>{option.name}</option>
                    )}
                </CustomInput>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="startDate">Von</Label>-<Label htmlFor="endDate">Bis</Label>
                <InputGroup>
                    <Input type="date"
                           name="startDate"
                           id="startDate"
                           value={currentFormState.startDate}
                           onChange={handleInputChange}
                           autoComplete="off"/>
                    <Input type="date"
                           name="endDate"
                           id="endDate"
                           value={currentFormState.endDate}
                           onChange={handleInputChange}
                           autoComplete="off"/>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <CustomInput type="switch"
                             name="fix"
                             id="fix"
                             label="Fix"
                             checked={currentFormState.fix}
                             onChange={handleSwitchChange('fix', currentFormState.fix)}
                             autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <CustomInput type="switch"
                             name="covid"
                             id="covid"
                             label="Corona"
                             checked={currentFormState.covid}
                             onChange={handleSwitchChange('covid', currentFormState.covid)}
                             autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <Input type="textarea"
                       name="text"
                       id="text"
                       value={currentFormState.text}
                       onChange={handleInputChange}
                       autoComplete="off"/>
            </FormGroup>
            <ButtonGroup>
                <Button color="primary">Speichern</Button>
                <Button color="secondary" onClick={() => props.setEditing(false)}>Abbrechen</Button>
            </ButtonGroup>
        </form>
    )
}

export default EditOrderForm
