import React, {useState} from 'react'
import {Button, CustomInput, FormGroup, Input, InputGroup, Label} from 'reactstrap';

const AddEventForm = props => {
    const initialFormState = {
        "id": "",
        "fkClient": -1,
        "fkItem": -1,
        "fkFriend": -1,
        "startDate": "",
        "endDate": "",
        "fix": 0,
        "covid": 0,
        "text": ""
    };
    const [currentFormState, setCurrentFormState] = useState(initialFormState)

    const handleInputChange = event => {
        const {name, value} = event.target

        setCurrentFormState({...currentFormState, [name]: value})
    }

    const handleCustomInputChange = event => {
        const {name, value} = event.target

        setCurrentFormState({...currentFormState, [name]: Number(value)})
    }

    const handleSwitchChange = (name, value) => () => {
        setCurrentFormState({...currentFormState, [name]: !value})
    }

    return (
        <form
            onSubmit={event => {
                event.preventDefault()
                if (!currentFormState.fkClient || !currentFormState.fkItem || !currentFormState.fkFriend) return

                props.addRow(currentFormState)
                setCurrentFormState(initialFormState)
            }}
        >
            <FormGroup>
                <Label htmlFor="fkClient">Kunde</Label>
                <CustomInput type="select"
                             name="fkClient"
                             id="fkClient"
                             onChange={handleCustomInputChange}
                             value={currentFormState.fkClient}
                             required>
                    <option key="-1"
                            value="-1"
                            disabled/>
                    {props.clients.map(option =>
                        <option key={option.id}
                                value={option.id}
                                disabled={option.debts ? true : null}>{option.name}</option>
                    )}
                </CustomInput>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="fkItem">Bühne</Label>
                <CustomInput type="select"
                             name="fkItem"
                             id="fkItem"
                             onChange={handleCustomInputChange}
                             value={currentFormState.fkItem}
                             required>
                    <option key="-1"
                            value="-1"
                            disabled/>
                    {props.items.map(option =>
                        <option key={option.id}
                                value={option.id}>{option.name}</option>
                    )}
                </CustomInput>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="fkFriend">Zuständig</Label>
                <CustomInput type="select"
                             name="fkFriend"
                             id="fkFriend"
                             onChange={handleCustomInputChange}
                             value={currentFormState.fkFriend}
                             required>
                    <option key="-1" value="-1" disabled/>
                    {props.friends.filter(friend => friend.lead).map(option =>
                        <option key={option.id}
                                value={option.id}>{option.name}</option>
                    )}
                </CustomInput>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="startDate">Von</Label>-<Label htmlFor="endDate">Bis</Label>
                <InputGroup>
                    <Input type="date"
                           name="startDate"
                           id="startDate"
                           value={currentFormState.startDate}
                           onChange={handleInputChange}
                           autoComplete="off"/>
                    <Input type="date"
                           name="endDate"
                           id="endDate"
                           value={currentFormState.endDate}
                           onChange={handleInputChange}
                           autoComplete="off"/>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <CustomInput type="switch"
                             name="fix"
                             id="fix"
                             label="Fix"
                             checked={currentFormState.fix}
                             onChange={handleSwitchChange('fix', currentFormState.fix)}
                             autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <CustomInput type="switch"
                             name="covid"
                             id="covid"
                             label="Corona"
                             checked={currentFormState.covid}
                             onChange={handleSwitchChange('covid', currentFormState.covid)}
                             autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <Input type="textarea"
                       name="text"
                       id="text"
                       value={currentFormState.text}
                       onChange={handleInputChange}
                       autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <Button color="primary">Hinzufügen</Button>
            </FormGroup>
        </form>
    )
}

export default AddEventForm
