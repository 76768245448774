import React, {useState} from 'react'
import {Button, CustomInput, FormGroup, Input, Label} from 'reactstrap';

const AddContactForm = props => {
    const initialFormState = {
        "id": "",
        "fkClient": -1,
        "name": "",
        "tel": "",
        "mail": ""
    }
    const [currentFormState, setCurrentFormState] = useState(initialFormState)

    const handleInputChange = event => {
        const {name, value} = event.target

        setCurrentFormState({...currentFormState, [name]: value})
    }

    const handleCustomInputChange = event => {
        const {name, value} = event.target

        setCurrentFormState({...currentFormState, [name]: Number(value)})
    }

    return (
        <form
            onSubmit={event => {
                event.preventDefault()
                if (!currentFormState.name) return

                props.addRow(currentFormState)
                setCurrentFormState(initialFormState)
            }}
        >
            <FormGroup>
                <Label htmlFor="name">Name</Label>
                <Input type="text"
                       name="name"
                       id="name"
                       value={currentFormState.name}
                       onChange={handleInputChange}
                       autoComplete="off"
                       required/>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="fkClient">Kunde</Label>
                <CustomInput type="select"
                             name="fkClient"
                             id="fkClient"
                             onChange={handleCustomInputChange}
                             value={currentFormState.fkClient}
                             required>
                    <option key="-1"
                            value="-1"
                            disabled/>
                    {props.clients.map(option =>
                        <option key={option.id}
                                value={option.id}>{option.name}</option>
                    )}
                </CustomInput>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="tel">Telefon</Label>
                <Input type="tel"
                       name="tel"
                       id="tel"
                       value={currentFormState.tel}
                       onChange={handleInputChange}
                       autoComplete="off"/>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="mail">E-Mail</Label>
                <Input type="mail"
                       name="mail"
                       id="mail"
                       value={currentFormState.mail}
                       onChange={handleInputChange}
                       autoComplete="off"/>
            </FormGroup>
            <Button color="primary">Hinzufügen</Button>
        </form>
    )
}

export default AddContactForm
